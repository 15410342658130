import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Image from "../components/imageIcon"

const ListLink = props => (
  <li
    style={{
      display: `inline-block`,
      marginRight: `1rem`,
      fontFamily: `Lexend Exa`,
      textDecoration: "none",
      color: "#f00",
    }}
  >
    <Link to={props.to}>{props.children}</Link>
  </li>
)
const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#eee`,
      marginBottom: `0.45rem`,
    }}
  >
    <div>
      <div className="container">
        <div className="row">
          <div className="col-2">
            <Link to="/">
              <Image />
            </Link>
          </div>
          <div className="col-10">
            <div className="row">  <span
              style={{
                fontFamily: `Anton`,
                fontSize: "2.5em",
                color: "#37b737",
                fontStyle: "italic",
              }}
            >
              GO Results
              </span>
            </div>
            <div className="row">
              <ul style={{ listStyle: `none`, color: "#f00" }}>
                <ListLink to="/">Home</ListLink>
                {/* <ListLink to="/about/">About</ListLink> */}
                <ListLink to="/contact/">Contact</ListLink>
              </ul></div>

          </div>
        </div>

      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
